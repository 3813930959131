import { render, staticRenderFns } from "./FortuneInfo.vue?vue&type=template&id=155ae907&scoped=true"
import script from "./FortuneInfo.vue?vue&type=script&lang=ts"
export * from "./FortuneInfo.vue?vue&type=script&lang=ts"
import style0 from "./FortuneInfo.vue?vue&type=style&index=0&id=155ae907&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "155ae907",
  null
  
)

export default component.exports