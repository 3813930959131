import { render, staticRenderFns } from "./FortuneLoading.vue?vue&type=template&id=f4ed2d7a&scoped=true"
import script from "./FortuneLoading.vue?vue&type=script&lang=ts"
export * from "./FortuneLoading.vue?vue&type=script&lang=ts"
import style0 from "./FortuneLoading.vue?vue&type=style&index=0&id=f4ed2d7a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f4ed2d7a",
  null
  
)

export default component.exports