import { Vue, Component } from 'vue-property-decorator'
import { MainCategory } from '../models/Category'

@Component
export default class CategoriesMixin extends Vue {
  mainCategories: MainCategory[] = [
    {
      name: '가족', // 1단계
      icon: '👪',
      subCategories: [
        { // 2단계
          name: '부모님과 관련된 꿈',
          items: [ // 3단계
            '부모님과 함께 식사하는 꿈',
            '부모님이 아프시거나 다치는 꿈',
            '부모님이 기뻐하시는 모습을 보는 꿈',
            '부모님과 말다툼하는 꿈',
            '부모님에게 칭찬받는 꿈',
            '부모님이 새로운 소식을 전해주는 꿈',
            '부모님이 나를 도와주는 꿈',
            '부모님과 여행을 가는 꿈',
            '부모님과 함께 대화하는 꿈',
            '부모님이 집을 떠나시는 꿈'
          ]
        },
        {
          name: '형제/자매와 관련된 꿈',
          items: [
            '형제/자매와 다투는 꿈',
            '형제/자매가 결혼하는 꿈',
            '형제/자매가 부모님과 다투는 꿈',
            '형제/자매가 나에게 선물을 주는 꿈',
            '형제/자매가 성공하는 꿈',
            '형제/자매가 이사하는 꿈',
            '형제/자매가 나에게 도움을 요청하는 꿈',
            '형제/자매가 여행을 가는 꿈',
            '형제/자매가 아프거나 다치는 꿈',
            '형제/자매가 나와 협력하는 꿈'
          ]
        },
        {
          name: '조부모님과 관련된 꿈',
          items: [
            '조부모님과 함께 시간을 보내는 꿈',
            '조부모님이 나를 돌봐주는 꿈',
            '조부모님이 병원에 가는 꿈',
            '조부모님이 예전 이야기를 해주는 꿈',
            '조부모님과 가족들이 모여 식사하는 꿈',
            '조부모님이 나에게 선물을 주는 꿈',
            '조부모님이 나와 산책하는 꿈',
            '조부모님이 나에게 힘을 주는 꿈',
            '조부모님이 건강해지시는 꿈',
            '조부모님이 이사가는 꿈'
          ]
        },
        {
          name: '자녀와 관련된 꿈',
          items: [
            '자녀가 학교에서 상을 받는 꿈',
            '자녀가 건강 문제를 겪는 꿈',
            '자녀가 결혼하는 꿈',
            '자녀가 성과를 내는 모습을 보는 꿈',
            '자녀가 나에게 도움을 요청하는 꿈',
            '자녀가 여행을 가는 꿈',
            '자녀가 나와 대화하는 꿈',
            '자녀가 부모를 찾는 꿈',
            '자녀가 새로운 직장을 얻는 꿈',
            '자녀가 가족을 위해 무언가를 하는 꿈'
          ]
        },
        {
          name: '배우자와 관련된 꿈',
          items: [
            '배우자와 함께 여행을 가는 꿈',
            '배우자와 다투는 꿈',
            '배우자가 새로운 일을 시작하는 꿈',
            '배우자가 나를 위해 희생하는 꿈',
            '배우자와 길을 걷는 꿈',
            '배우자와 함께 식사를 하는 꿈',
            '배우자와 기념일을 함께 보내는 꿈',
            '배우자가 나에게 선물을 주는 꿈',
            '배우자와 결혼을 준비하는 꿈',
            '배우자와 함께 쇼핑하는 꿈'
          ]
        },
        {
          name: '친척과 관련된 꿈',
          items: [
            '친척이 결혼하는 꿈',
            '친척과 함께 여행하는 꿈',
            '친척이 나에게 돈을 빌려주는 꿈',
            '친척이 이사하는 꿈',
            '친척과 오랜만에 만나는 꿈',
            '친척이 나를 도와주는 꿈',
            '친척이 나에게 선물을 주는 꿈',
            '친척이 가족 모임을 주최하는 꿈',
            '친척과 함께 저녁을 먹는 꿈',
            '친척이 중요한 소식을 전해주는 꿈'
          ]
        },
        {
          name: '가족의 결혼과 관련된 꿈',
          items: [
            '자녀가 결혼하는 꿈',
            '형제/자매가 결혼하는 꿈',
            '가까운 친척이 결혼하는 꿈',
            '부모님이 재혼하는 꿈',
            '가족 결혼식에 참석하는 꿈',
            '가족 결혼식에서 문제가 생기는 꿈',
            '결혼식에서 가족과 함께 사진을 찍는 꿈',
            '가족 결혼식에서 가족들이 모이는 꿈',
            '가족 결혼식에서 내가 중요한 역할을 맡는 꿈',
            '가족 결혼식에서 신부/신랑을 축하하는 꿈'
          ]
        },
        {
          name: '가족의 특별한 행사와 관련된 꿈',
          items: [
            '가족과 함께 바비큐를 하는 꿈',
            '가족과 함께 응원을 가는 꿈',
            '가족과 함께 명절을 보내는 꿈',
            '가족과 함께 영화를 보는 꿈',
            '가족 모임에서 음식을 먹는 꿈',
            '가족과 함께 행사에 참석하는 꿈',
            '가족과 특별한 이벤트에 참여하는 꿈',
            '가족과 함께 축제를 즐기는 꿈',
            '가족과 함께 기념일을 축하하는 꿈',
            '가족과 여행을 계획하는 꿈'
          ]
        },
        {
          name: '가족의 이별과 관련된 꿈',
          items: [
            '가족이 이사를 가는 꿈',
            '가족 중 한 명이 멀리 떠나는 꿈',
            '가족이 해외로 이민 가는 꿈',
            '가족과 이별하는 장면을 보는 꿈',
            '가족이 이혼하는 꿈',
            '가족이 죽음으로 이별하는 꿈',
            '가족 중 한 명이 직장을 옮기는 꿈',
            '가족이 장기 출장으로 떠나는 꿈',
            '가족과 대화 없이 이별하는 꿈',
            '이별 후 가족과 재회하는 꿈'
          ]
        },
        {
          name: '가족의 건강과 관련된 꿈',
          items: [
            '부모님이 병원에 입원하는 꿈',
            '자녀가 병에 걸리는 꿈',
            '배우자가 건강 검진을 받는 꿈',
            '가족 중 한 명이 큰 수술을 받는 꿈',
            '형제/자매가 건강을 회복하는 꿈',
            '가족이 다이어트나 운동을 시작하는 꿈',
            '조부모님이 갑자기 건강해지는 꿈',
            '가족이 스트레스를 해소하는 꿈',
            '가족이 병에서 회복되는 꿈',
            '가족이 건강 회복을 돕기 위해 노력하는 꿈'
          ]
        }
      ]
    },
    {
      name: '재물',
      icon: '💰',
      subCategories: [
        {
          name: '돈과 관련된 꿈',
          items: [
            '돈을 많이 버는 꿈',
            '돈을 잃어버리는 꿈',
            '돈을 훔치는 꿈',
            '누군가에게 돈을 주는 꿈',
            '돈이 갑자기 사라지는 꿈',
            '돈이 하늘에서 떨어지는 꿈',
            '주머니에서 돈을 찾는 꿈',
            '돈이 바닥에 놓여있는 꿈',
            '동전이나 지폐를 줍는 꿈',
            '돈을 훔쳐 도망가는 꿈'
          ]
        },
        {
          name: '재산과 관련된 꿈',
          items: [
            '부동산을 구입하는 꿈',
            '재산이 크게 증가하는 꿈',
            '재산을 잃어버리는 꿈',
            '유산을 받는 꿈',
            '돈 재산을 계산하는 꿈',
            '재산이 계약서에 등록되는 꿈',
            '자산이 급격히 불어나는 꿈',
            '재산을 파는 꿈',
            '재산을 관리하는 꿈',
            '재산을 물려받는 꿈'
          ]
        },
        {
          name: '유산과 관련된 꿈',
          items: [
            '가족으로부터 유산을 받는 꿈',
            '유산을 둘러싸고 가족 갈등을 보는 꿈',
            '유산을 다른 사람에게 양도하는 꿈',
            '남의 유산을 받는 꿈',
            '유산을 어떻게 사용할지 고민하는 꿈',
            '유산을 포기하는 꿈',
            '유산을 나눠주는 꿈',
            '유산을 숨기려 하는 꿈',
            '유산을 감추는 꿈',
            '유산을 분배하는 꿈'
          ]
        },
        {
          name: '투자와 관련된 꿈',
          items: [
            '주식에 투자하는 꿈',
            '부동산에 투자하는 꿈',
            '투자에서 큰 손실을 보는 꿈',
            '재산 자산이 증가하는 꿈',
            '친구에게 투자 권유를 받는 꿈',
            '위험한 투자에 뛰어드는 꿈',
            '투자에서 이익을 보는 꿈',
            '금에 투자하는 꿈',
            '새로운 자산에 투자하는 꿈',
            '투자 실패 후 성공하는 꿈'
          ]
        },
        {
          name: '채무와 관련된 꿈',
          items: [
            '빚을 지는 꿈',
            '빚을 청산하는 꿈',
            '누군가에게 빚을 갚는 꿈',
            '빚 때문에 압박을 받는 꿈',
            '빚이 해결되지 않아 고통받는 꿈',
            '돈을 빌리고 갚지 못하는 꿈',
            '빚을 갚기 위해 노력하는 꿈',
            '빚을 청산하지 못해 도망치는 꿈',
            '빚을 회피하는 꿈',
            '빚이 아예 사라지는 꿈'
          ]
        },
        {
          name: '상환과 관련된 꿈',
          items: [
            '대출금을 상환하는 꿈',
            '큰 금액을 상환하고 자유로워지는 꿈',
            '돈을 빌리고 바로 갚는 꿈',
            '상환 기간이 연장되는 꿈',
            '상환을 못 해서 걱정하는 꿈',
            '빌린 돈을 갚기 위해 노력하는 꿈',
            '다른 사람의 빚을 대신 갚는 꿈',
            '빚의 중요성에 대해 생각하는 꿈',
            '빚 상환에 대한 압박을 받는 꿈',
            '상환이 완료되어 기뻐하는 꿈'
          ]
        },
        {
          name: '복권과 관련된 꿈',
          items: [
            '복권에 당첨되는 꿈',
            '복권을 사러 가는 꿈',
            '복권 번호를 맞추는 꿈',
            '복권에 큰 돈을 따는 꿈',
            '복권에 여러 번 당첨되는 꿈',
            '복권에 실패하는 꿈',
            '복권을 사지 못하는 꿈',
            '복권 당첨금을 기부하는 꿈',
            '복권에 당첨된 이야기를 듣는 꿈',
            '복권을 태우는 꿈'
          ]
        },
        {
          name: '금과 관련된 꿈',
          items: [
            '금을 발견하는 꿈',
            '금을 녹여서 재사용하는 꿈',
            '금이 빛나는 꿈',
            '금 반지를 끼는 꿈',
            '금으로 된 건물을 짓는 꿈',
            '금을 훔치는 꿈',
            '금을 사고파는 꿈',
            '금화를 얻는 꿈',
            '금은으로 된 물건을 잃어버리는 꿈',
            '금을 숨기는 꿈'
          ]
        },
        {
          name: '물건과 관련된 꿈',
          items: [
            '귀중한 물건을 잃어버리는 꿈',
            '새 물건을 구입하는 꿈',
            '물건이 망가지는 꿈',
            '값진 물건을 찾는 꿈',
            '물건을 도둑맞는 꿈',
            '물건을 사는 꿈',
            '물건을 팔려고 고민하는 꿈',
            '값진 물건을 선물받는 꿈',
            '중고품을 구입하는 꿈',
            '큰 물건을 집에 들이는 꿈'
          ]
        },
        {
          name: '보상과 관련된 꿈',
          items: [
            '회사나 기관으로부터 보상을 받는 꿈',
            '금전적 보상을 요구하는 꿈',
            '손해를 미친 보상을 받는 꿈',
            '보상이 거부되는 꿈',
            '보상이 너무 적게 느껴지는 꿈',
            '보상이 허상임을 깨닫는 꿈',
            '보상이 사라지는 꿈',
            '보상을 기다리는 꿈',
            '보상금을 받는 꿈',
            '정당한 보상을 받지 못하는 꿈'
          ]
        }
      ]
    },
    {
      name: '건강',
      icon: '💪',
      subCategories: [
        {
          name: '질병과 관련된 꿈',
          items: [
            '자신이 질병을 앓고 있는 꿈',
            '가족이 병에 걸리는 꿈',
            '질병을 치료하기 위해 병원을 찾는 꿈',
            '위독해져 걸리는 꿈',
            '질병으로 인해 일상생활을 못하는 꿈',
            '병의 원인을 찾지 못하는 꿈',
            '질병으로 인해 몸이 쇠약해지는 꿈',
            '병이 악화되는 꿈',
            '진단 결과가 잘못된 꿈',
            '질병으로 인해 회복을 기다리는 꿈'
          ]
        },
        {
          name: '상해(부상)과 관련된 꿈',
          items: [
            '사고로 다치는 꿈',
            '교통사고로 부상을 입는 꿈',
            '날카로운 물건에 찔리는 꿈',
            '넘어져서 부상을 입는 꿈',
            '스포츠 중 부상을 당하는 꿈',
            '화상으로 인해 상처를 입는 꿈',
            '물에 빠져 큰 부상을 당하는 꿈',
            '머리를 다치는 꿈',
            '손이나 발이 부러지는 꿈',
            '공사장에서 사고를 당하는 꿈'
          ]
        },
        {
          name: '재활과 관련된 꿈',
          items: [
            '사고 후 재활치료를 받는 꿈',
            '병원에서 물리치료를 받는 꿈',
            '재활센터에 있는 꿈',
            '부상 후 걷는 연습을 하는 꿈',
            '다친 후 운동으로 회복하는 꿈',
            '재활치료가 순조롭게 진행되는 꿈',
            '재활 과정에서 좌절을 겪는 꿈',
            '재활치료 도중 성공적으로 회복하는 꿈',
            '재활 도중 다시 다치는 꿈',
            '의료진과 함께 재활 프로그램을 계획하는 꿈'
          ]
        },
        {
          name: '병원과 관련된 꿈',
          items: [
            '병원에서 진단을 받는 꿈',
            '응급실에서 치료를 받는 꿈',
            '병원에서 수술을 준비하는 꿈',
            '입원하는 꿈',
            '의사가 병명을 설명해주는 꿈',
            '병원에서 퇴원하는 꿈',
            '병원의 혼잡한 대기실에서 기다리는 꿈',
            '병원에서 간호사를 만나는 꿈',
            '병원에서 가족을 간호하는 꿈',
            '병원의 검사 결과를 듣는 꿈'
          ]
        },
        {
          name: '정신 건강과 관련된 꿈',
          items: [
            '정신과 상담을 받는 꿈',
            '불안증을 겪는 꿈',
            '우울증으로 고통받는 꿈',
            '스트레스를 극복하는 꿈',
            '정신 건강을 위한 명상을 하는 꿈',
            '심리 치료를 받는 꿈',
            '감정 조절을 못하는 꿈',
            '정신적 고통으로 인해 잠을 못 자는 꿈',
            '정신적으로 회복되는 꿈',
            '긍정적인 정신 건강 상태를 회복하는 꿈'
          ]
        },
        {
          name: '복용약과 관련된 꿈',
          items: [
            '의사의 처방으로 약을 복용하는 꿈',
            '약을 잊고 복용하지 않는 꿈',
            '약을 복용한 후 부작용을 겪는 꿈',
            '잘못된 약을 복용하는 꿈',
            '약을 복용하는 것을 잊는 꿈',
            '약이 썩는 꿈',
            '약을 찾지 못해 혼란스러운 꿈',
            '약을 복용 후 즉시 효과를 보는 꿈',
            '약을 복용하지 않고 회복하는 꿈',
            '약을 복용 후 기분이 나아지는 꿈'
          ]
        },
        {
          name: '운동과 관련된 꿈',
          items: [
            '체육관에서 운동하는 꿈',
            '마라톤 경주에 참여하는 꿈',
            '운동으로 체력을 회복하는 꿈',
            '힘든 운동을 견디는 꿈',
            '운동 중 부상을 당하는 꿈',
            '요가나 명상에 몰두하는 꿈',
            '수영장에서 운동하는 꿈',
            '운동으로 체중을 감량하는 꿈',
            '운동 후 땀을 흘리며 기뻐하는 꿈',
            '운동으로 건강을 유지하는 꿈'
          ]
        },
        {
          name: '의료 기기와 관련된 꿈',
          items: [
            'MRI 기계를 사용하는 꿈',
            '휠체어를 타는 꿈',
            '인공호흡기를 사용하는 꿈',
            'X-ray 촬영을 하는 꿈',
            '심장 모니터링 장비를 사용하는 꿈',
            '산소기를 사용하는 꿈',
            '의료 기기를 구입하는 꿈',
            '의료 기기가 고장 나는 꿈',
            '혈압계와 기구를 사용하는 꿈',
            '의료 장비를 통해 진단을 받는 꿈'
          ]
        }
        ]
      },
      {
        name: '직업',
        icon: '💼',
        subCategories: [
          {
            name: '취업과 관련된 꿈',
            items: [
              '면접을 통과하는 꿈',
              '취업을 준비하는 꿈',
              '취업 시험에 합격하는 꿈',
              '합격 소식을 듣는 꿈',
              '경력직으로 채용되는 꿈',
              '갑작스럽게 직장을 구하는 꿈',
              '외국 회사에 취직하는 꿈',
              '다른 회사에서 동시에 합격 통보를 받는 꿈',
              '취업을 위한 교육을 받는 꿈',
              '취업에 실패하는 꿈'
            ]
          },
          {
            name: '성과에 관련된 꿈',
            items: [
              '직장에서 목표를 달성하는 꿈',
              '성과가 성장하는 꿈',
              '중요한 계약을 성사시키는 꿈',
              '프로젝트를 성공적으로 마치는 꿈',
              '새로운 아이디어로 성과를 얻는 꿈',
              '성과 보너스를 받는 꿈',
              '성과가 나빠지는 꿈',
              '성과가 올라가는 꿈',
              '성과를 기록하는 꿈',
              '성과를 기념하는 꿈'
            ]
          },
          {
            name: '해고와 관련된 꿈',
            items: [
              '해고 통보를 받는 꿈',
              '직장에서 문제를 일으키는 꿈',
              '해고 후 직장을 되찾는 꿈',
              '상사가 해고를 통보하는 꿈',
              '직장이 없어져 고민하는 꿈',
              '회사에서 구조조정이 일어나는 꿈',
              '직장 내 문제가 생기는 꿈',
              '해고 후 다른 직장을 구하는 꿈',
              '회사에서 해고된 후 다시 복직하는 꿈',
              '해고를 막으려는 꿈'
            ]
          },
          {
            name: '동료와 관련된 꿈',
            items: [
              '동료와 함께 일하는 꿈',
              '동료와 함께 프로젝트를 진행하는 꿈',
              '동료와 다투는 꿈',
              '동료가 회사를 떠나는 꿈',
              '새로운 동료가 들어오는 꿈',
              '동료가 승진하는 꿈',
              '동료의 말을 듣는 꿈',
              '동료에게 조언을 받는 꿈',
              '동료가 나에게 도움을 주는 꿈',
              '동료와 협력하는 꿈'
            ]
          },
          {
            name: '상사와 관련된 꿈',
            items: [
              '상사에게 보고하는 꿈',
              '상사의 칭찬을 받는 꿈',
              '상사와 함께 일하는 꿈',
              '상사가 나를 꾸짖는 꿈',
              '상사에게 승진을 요청하는 꿈',
              '상사가 회의에서 중요 역할을 맡는 꿈',
              '상사에게 인정을 받는 꿈',
              '상사와 대화하는 꿈',
              '상사의 업무를 대리하는 꿈',
              '상사와 함께 출장을 가는 꿈'
            ]
          },
          {
            name: '직장 내 갈등과 관련된 꿈',
            items: [
              '동료와 갈등을 겪는 꿈',
              '상사의 의견 갈등이 생기는 꿈',
              '부하 직원과 문제가 생기는 꿈',
              '직장 내 따돌림을 당하는 꿈',
              '갈등을 해결하는 꿈',
              '직장 내 정치적 갈등을 겪는 꿈',
              '팀 내에서 의견 충돌이 생기는 꿈',
              '상사의 지시와 갈등을 겪는 꿈',
              '팀 회의에서 갈등을 푸는 꿈',
              '직장 내 문제가 해결되는 꿈'
            ]
          },
          {
            name: '프로젝트와 관련된 꿈',
            items: [
              '새로운 프로젝트를 시작하는 꿈',
              '중요한 프로젝트를 성공적으로 끝내는 꿈',
              '프로젝트에서 문제가 생기는 꿈',
              '프로젝트를 기한 내에 끝내는 꿈',
              '프로젝트를 성공적으로 마치는 꿈',
              '프로젝트를 완성하지 못하는 꿈',
              '프로젝트 팀을 이끄는 꿈',
              '프로젝트를 실패하는 꿈',
              '프로젝트가 중단되는 꿈',
              '프로젝트에서 성과를 올리는 꿈'
            ]
          },
          {
            name: '직업적 변화와 관련된 꿈',
            items: [
              '직장을 옮기는 꿈',
              '직책을 넘어서는 꿈',
              '직업적 변화를 겪는 꿈',
              '회사를 그만두는 꿈',
              '다른 회사로 이직하는 꿈',
              '새로운 업무를 시작하는 꿈',
              '직업을 바꾸는 꿈',
              '직업적 성장의 기회를 찾는 꿈',
              '커리어 전환을 계획하는 꿈',
              '직장 교육을 받는 꿈'
            ]
          },
          {
            name: '직장 내 평가와 관련된 꿈',
            items: [
              '상사로부터 평가를 받는 꿈',
              '업무 성과 평가를 받는 꿈',
              '동료의 평가를 들리는 꿈',
              '평가에서 좋은 점수를 받는 꿈',
              '평가 후 보상을 받는 꿈',
              '평가가 지연되는 꿈',
              '평가 결과가 좋지 않은 꿈',
              '평가가 두려워지는 꿈',
              '평가에서 낮은 점수를 받는 꿈',
              '평가 후 승진하는 꿈'
            ]
          },
          {
            name: '직장 내 교육과 관련된 꿈',
            items: [
              '직장에서 교육을 받는 꿈',
              '새로운 직무를 위한 교육을 받는 꿈',
              '상사가 교육을 주관하는 꿈',
              '직장 내 전문 교육 프로그램에 참여하는 꿈',
              '회사의 외부 교육 프로그램에 참여하는 꿈',
              '업무 관련 강의를 듣는 꿈',
              '직장에서 자격증 시험을 준비하는 꿈',
              '새로운 기술을 배우는 꿈',
              '직장 내 교육 평가를 받는 꿈',
              '교육에서 성공적으로 수료하는 꿈'
            ]
          }
        ]
      },
      {
        name: '여행',
        icon: '✈️',
        subCategories: [
          {
            name: '해외 여행과 관련된 꿈',
            items: [
              '해외로 떠나는 비행기를 타는 꿈',
              '해외에서 모르는 사람을 만나는 꿈',
              '외국에서 길을 잃는 꿈',
              '해외에서 유명한 관광지를 방문하는 꿈',
              '해외에서 낯선 음식을 먹는 꿈',
              '해외에서 예상치 못한 사고를 당하는 꿈',
              '외국어로 대화하는 꿈',
              '해외에서 도둑으로부터 물건을 잃어버리는 꿈',
              '해외에서 귀국하는 비행기를 타는 꿈',
              '동아시아로 출장을 가는 꿈'
            ]
          },
          {
            name: '국내 여행과 관련된 꿈',
            items: [
              '국내 유명한 관광지를 방문하는 꿈',
              '국내에서 도보 여행을 하는 꿈',
              '고속도로를 타고 여행하는 꿈',
              '기차로 국내 여행을 가는 꿈',
              '섬이나 내륙을 여행하는 꿈',
              '국내 여행 중 호텔에서 쉬는 꿈',
              '국내 여행 중 사진을 찍는 꿈',
              '국내 여행에서 추억을 만드는 꿈',
              '국내에서 자전거를 타는 꿈',
              '국내 여행 중 길을 잃는 꿈'
            ]
          },
          {
            name: '혼자 여행과 관련된 꿈',
            items: [
              '혼자 여행을 떠나는 꿈',
              '혼자 여행 중 낯선 곳에서 길을 찾는 꿈',
              '혼자 여행하며 명소를 방문하는 꿈',
              '혼자 여행 중 자유로움을 느끼는 꿈',
              '혼자 여행 중 피곤한 상황에 처하는 꿈',
              '혼자 여행하며 자신을 돌아보는 꿈',
              '혼자 여행에서 사진을 찍는 꿈',
              '혼자 여행 중 모르는 사람을 만나는 꿈',
              '혼자 여행하며 고독함을 느끼는 꿈',
              '혼자 여행 중 불안함을 겪는 꿈'
            ]
          },
          {
            name: '단체 여행과 관련된 꿈',
            items: [
              '단체로 버스를 타고 여행하는 꿈',
              '단체로 기차를 타고 여행하는 꿈',
              '단체 여행 중 물건을 잃어버리는 꿈',
              '단체 여행에서 식사를 함께 하는 꿈',
              '단체 관광지에서 문제를 겪는 꿈',
              '단체 여행 중 친구들이 생기는 꿈',
              '단체로 길을 잃고 헤매는 꿈',
              '단체 여행 중 멋진 사진을 찍는 꿈',
              '단체 여행에서 즐거운 시간을 보내는 꿈',
              '단체 여행에서 모두가 함께 식사하는 꿈'
            ]
          },
          {
            name: '친구와의 여행과 관련된 꿈',
            items: [
              '친구와 함께 여행을 계획하는 꿈',
              '친구와 함께 비행기를 타고 있는 꿈',
              '친구와 함께 여행지에서 사진을 찍는 꿈',
              '친구와 여행 중 다투는 꿈',
              '친구와 여행 중 사고를 당하는 꿈',
              '친구와 함께 새로운 곳을 발견하는 꿈',
              '친구와 함께 음식점을 찾는 꿈',
              '친구와 함께 술을 마시는 꿈',
              '친구와 함께 여행을 가는 꿈',
              '친구와 함께 숙소를 찾는 꿈'
            ]
          },
          {
            name: '가족과의 여행과 관련된 꿈',
            items: [
              '가족과 함께 여행을 떠나는 꿈',
              '가족과 함께 여행을 즐기는 꿈',
              '가족과 여행 중 새로운 장소를 발견하는 꿈',
              '가족과 여행 중 도중 사고를 당하는 꿈',
              '가족과 여행 중 도둑을 당하는 꿈',
              '가족과 함께 음식을 먹는 꿈',
              '가족과 함께 명소를 방문하는 꿈',
              '가족과 함께 여행 중 길을 잃는 꿈',
              '가족과 함께 관광지를 구경하는 꿈',
              '가족과 함께 관광 투어를 하는 꿈'
            ]
          },
          {
            name: '비행기 여행과 관련된 꿈',
            items: [
              '비행기를 타고 여행하는 꿈',
              '비행기에서 착륙을 기다리는 꿈',
              '비행기에서 만난 승무원과 대화하는 꿈',
              '비행기로 해외로 출장을 가는 꿈',
              '비행기가 추락하는 꿈',
              '비행기에서 여러 번 당첨되는 꿈',
              '비행기에서 다른 승객을 만나는 꿈',
              '비행기에서 사고를 겪는 꿈',
              '비행기에서 하늘을 나는 꿈',
              '비행기 안에서 자리를 찾는 꿈'
            ]
          },
          {
            name: '차량 여행과 관련된 꿈',
            items: [
              '차를 타고 여행을 떠나는 꿈',
              '차량 여행 중 도중 사고를 겪는 꿈',
              '차량 여행 중 새로운 곳을 발견하는 꿈',
              '차로 여행 중 도중 길을 잃는 꿈',
              '차량 여행 중 음식을 구입하는 꿈',
              '차량을 몰고 여행 중 풍경을 감상하는 꿈',
              '차량 여행 중 휴게소를 찾는 꿈',
              '차량 여행 중 친구와 함께 운전하는 꿈',
              '차량이 고장 나는 꿈',
              '차량 여행 중 도로에서 위험한 상황을 겪는 꿈'
            ]
          },
          {
            name: '여행 중 만나는 사람과 관련된 꿈',
            items: [
              '여행 중 낯선 사람을 만나는 꿈',
              '여행 도중 친구를 만나는 꿈',
              '여행 중 현지 주민과 대화하는 꿈',
              '여행 중 새로운 인연을 만나는 꿈',
              '여행 중 안내소에서 대화하는 꿈',
              '여행 중 만난 동행자와 대화하는 꿈',
              '여행 중 만난 사람이 도와주는 꿈',
              '여행 중 친구를 우연히 만나는 꿈',
              '여행 중 만난 가이드를 돕는 꿈',
              '여행 도중 만난 사람과 함께 시간을 보내는 꿈'
            ]
          },
          {
            name: '여행 준비와 관련된 꿈',
            items: [
              '여권을 준비하는 꿈',
              '여권 검사를 통과하는 꿈',
              '여권을 잃어버리는 꿈',
              '여행을 준비하는 꿈',
              '여행 비용을 계산하는 꿈',
              '여행 가방을 꾸리는 꿈',
              '여행 짐을 확인하는 꿈',
              '여행 목적지를 선택하는 꿈',
              '여행 정보를 검색하는 꿈',
              '여행 티켓을 구입하는 꿈'
            ]
          }
        ]
      },
      {
        name: '동물',
        icon: '🐾',
        subCategories: [
          {
            name: '개와 관련된 꿈',
            items: [
              '개에게 물리는 꿈',
              '개가 도망가는 꿈',
              '개를 잃는 꿈',
              '개를 죽이는 꿈',
              '개가 음식을 감싸는 꿈',
              '어디서부터인지 모르게 개가 나오는 꿈',
              '큰 개를 보는 꿈',
              '개가 싸우는 꿈',
              '개가 나를 공격하는 꿈',
              '새끼 강아지를 보는 꿈'
            ]
          },
          {
            name: '돼지와 관련된 꿈',
            items: [
              '돼지를 키우는 꿈',
              '돼지가 집에 들어오는 꿈',
              '돼지가 새끼를 낳는 꿈',
              '돼지를 잡는 꿈',
              '돼지를 팔거나 사는 꿈',
              '돼지가 도망가는 꿈',
              '돼지를 먹는 꿈',
              '돼지가 집안을 어지럽히는 꿈',
              '돼지가 죽는 꿈',
              '돼지와 대화하는 꿈'
            ]
          },
          {
            name: '고양이와 관련된 꿈',
            items: [
              '고양이가 울부짖는 꿈',
              '고양이가 생쥐를 잡는 꿈',
              '고양이에게 긁히는 꿈',
              '고양이가 집에서 도망가는 꿈',
              '길고양이를 만나는 꿈',
              '고양이가 새끼를 낳는 꿈',
              '검은 고양이를 보는 꿈',
              '고양이가 집을 파괴하는 꿈',
              '고양이가 나를 지켜보는 꿈',
              '고양이가 나에게 다가오는 꿈'
            ]
          },
          {
            name: '말과 관련된 꿈',
            items: [
              '말을 타고 달리는 꿈',
              '말이 자유롭게 뛰어노는 꿈',
              '말에게 먹이를 주는 꿈',
              '말이 더러워지는 꿈',
              '말이 새끼를 낳는 꿈',
              '아픈 말을 돌보는 꿈',
              '말이 울부짖는 꿈',
              '말에 쫓기는 꿈',
              '말이 바다로 뛰어드는 꿈',
              '말이 길을 잃는 꿈'
            ]
          },
          {
            name: '새와 관련된 꿈',
            items: [
              '새가 하늘을 나는 꿈',
              '새를 잡는 꿈',
              '새가 집 안으로 들어오는 꿈',
              '새가 둥지를 트는 꿈',
              '새가 울어대는 꿈',
              '새가 알을 낳는 꿈',
              '큰 새를 보는 꿈',
              '새가 죽는 꿈',
              '새가 나에게 다가오는 꿈',
              '여러 마리 새가 함께 날아가는 꿈'
            ]
          },
          {
            name: '물고기와 관련된 꿈',
            items: [
              '물고기가 잡히는 꿈',
              '물고기가 수영하며 뛰어오르는 꿈',
              '물고기가 물 위로 올라오는 꿈',
              '물고기를 잡는 꿈',
              '물고기가 죽는 꿈',
              '큰 물고기를 보는 꿈',
              '물고기가 새끼를 낳는 꿈',
              '물고기 떼가 몰려오는 꿈',
              '물고기가 사라지는 꿈',
              '물고기가 바다로 뛰어드는 꿈'
            ]
          },
          {
            name: '호랑이와 관련된 꿈',
            items: [
              '호랑이와 마주치는 꿈',
              '호랑이가 사라지는 꿈',
              '호랑이가 길을 걷는 꿈',
              '호랑이가 먹이를 찾는 꿈',
              '호랑이가 멀리서 다가오는 꿈',
              '호랑이가 나를 공격하는 꿈',
              '호랑이가 사라지는 꿈',
              '호랑이에게 쫓기는 꿈',
              '호랑이가 나타나는 꿈',
              '호랑이와 사투를 벌이는 꿈'
            ]
          },
          {
            name: '소와 관련된 꿈',
            items: [
              '소가 들판을 걸어가는 꿈',
              '소가 집으로 들어오는 꿈',
              '소를 타고 이동하는 꿈',
              '소가 새끼를 낳는 꿈',
              '소가 밭을 가는 꿈',
              '소가 풀을 뜯는 꿈',
              '황소가 싸우는 꿈',
              '소에게 먹이를 주는 꿈',
              '소가 도망가는 꿈',
              '소가 울부짖는 꿈'
            ]
          },
          {
            name: '뱀과 관련된 꿈',
            items: [
              '뱀이 나타나는 꿈',
              '뱀이 사라지는 꿈',
              '뱀이 나를 공격하는 꿈',
              '뱀이 물에 빠지는 꿈',
              '뱀이 나무를 타는 꿈',
              '뱀이 알을 낳는 꿈',
              '큰 뱀이 나타나는 꿈',
              '뱀을 잡는 꿈',
              '뱀이 다른 동물을 공격하는 꿈',
              '뱀과 싸우는 꿈'
            ]
          },
          {
            name: '사자와 관련된 꿈',
            items: [
              '사자가 나타나는 꿈',
              '사자가 나를 공격하는 꿈',
              '사자가 물을 마시는 꿈',
              '사자가 싸우는 꿈',
              '사자가 사냥하는 꿈',
              '사자와 함께 걷는 꿈',
              '사자가 나를 지키는 꿈',
              '사자가 새끼를 낳는 꿈',
              '사자가 죽는 꿈',
              '사자가 크게 포효하는 꿈'
            ]
          }
        ]
      },
      {
        name: '자연',
        icon: '🌿',
        subCategories: [
          {
            name: '자연재해와 관련된 꿈',
            items: [
              '지진이 발생하는 꿈',
              '해일이 밀려오는 꿈',
              '산사태가 일어나는 꿈',
              '화산이 폭발하는 꿈',
              '태풍이 휘몰아치는 꿈',
              '홍수로 인해 물이 차오르는 꿈',
              '쓰나미가 도시를 덮치는 꿈',
              '강풍으로 인해 창문이 부서지는 꿈',
              '번개가 떨어지는 꿈',
              '자연재해로 인해 도망치는 꿈'
            ]
          },
          {
            name: '바다와 관련된 꿈',
            items: [
              '맑은 바다에서 수영하는 꿈',
              '거친 파도를 타는 꿈',
              '바다 위에서 배를 타는 꿈',
              '깊은 바다 속으로 들어가는 꿈',
              '바다에서 낚시하는 꿈',
              '바다에서 보물을 발견하는 꿈',
              '바다를 항해하는 꿈',
              '바다에서 길을 잃는 꿈',
              '바다에서 모래사장에서 쉬는 꿈',
              '바다에서 돌고래를 만나는 꿈'
            ]
          },
          {
            name: '비와 관련된 꿈',
            items: [
              '폭우가 쏟아지는 꿈',
              '가랑비가 내리는 꿈',
              '천둥과 함께 비가 내리는 꿈',
              '강수로 인해 비바람이 치는 꿈',
              '갑작스럽게 소나기가 내리는 꿈',
              '비로 인해 홍수가 나는 꿈',
              '비오는 날 우산을 쓰고 걷는 꿈',
              '비가 멈추고 무지개가 뜨는 꿈',
              '비에 젖어 옷이 젖는 꿈',
              '비바람 속에서 집으로 돌아오는 꿈'
            ]
          },
          {
            name: '산과 관련된 꿈',
            items: [
              '산을 오르는 꿈',
              '산 정상에 도착하는 꿈',
              '산악에서 길을 잃는 꿈',
              '천연의 폭포를 보는 꿈',
              '산 아래로 내려오는 꿈',
              '산을 등반하다가 다치는 꿈',
              '푸른 산을 바라보는 꿈',
              '산에서 동물을 만나는 꿈',
              '산에서 캠핑을 하는 꿈',
              '눈 덮인 산을 보는 꿈'
            ]
          },
          {
            name: '눈과 관련된 꿈',
            items: [
              '함박눈이 내리는 꿈',
              '눈이 쌓이는 꿈',
              '눈이 녹아 사라지는 꿈',
              '눈 속에서 길을 잃는 꿈',
              '눈 위에서 미끄러지는 꿈',
              '눈을 밟고 걷는 꿈',
              '눈 속에서 산을 오르는 꿈',
              '눈사람을 만드는 꿈',
              '눈이 바람에 날리는 꿈',
              '눈덮인 풍경을 바라보는 꿈'
            ]
          },
          {
            name: '폭풍과 관련된 꿈',
            items: [
              '거센 폭풍이 몰아치는 꿈',
              '폭풍으로 인해 집이 흔들리는 꿈',
              '폭풍 속에서 길을 찾는 꿈',
              '폭풍 속에서 도망치는 꿈',
              '폭풍으로 인해 나무가 쓰러지는 꿈',
              '폭풍 속에서 고요해지는 꿈',
              '폭풍으로 인해 번개가 떨어지는 꿈',
              '폭풍이 지나가고 무지개가 뜨는 꿈',
              '폭풍 속에서 다른 사람을 구하는 꿈',
              '폭풍 속에서 비를 맞는 꿈'
            ]
          },
          {
            name: '하늘과 관련된 꿈',
            items: [
              '맑은 하늘을 바라보는 꿈',
              '구름이 떠다니는 하늘을 보는 꿈',
              '하늘이 빛나는 꿈',
              '무지개가 하늘에 뜨는 꿈',
              '하늘에서 비행기가 날아가는 꿈',
              '별이 가득한 밤하늘을 보는 꿈',
              '하늘에서 전투기들이 날아가는 꿈',
              '하늘에서 폭풍이 몰아치는 꿈',
              '하늘에 해가 뜨는 꿈',
              '하늘에서 빛이 내려오는 꿈'
            ]
          },
          {
            name: '강과 관련된 꿈',
            items: [
              '잔잔한 강물을 바라보는 꿈',
              '강에서 수영하는 꿈',
              '강변에서 캠핑을 하는 꿈',
              '강에서 배를 타고 여행하는 꿈',
              '강변에서 산책하는 꿈',
              '강가에서 일출을 감상하는 꿈',
              '강에서 물고기를 잡는 꿈',
              '강에서 튜브를 타는 꿈',
              '강이 범람하는 꿈',
              '강 위에서 다리를 건너는 꿈'
            ]
          },
          {
            name: '숲과 관련된 꿈',
            items: [
              '울창한 숲을 걷는 꿈',
              '숲속에서 동물을 만나는 꿈',
              '숲에서 길을 잃는 꿈',
              '숲속에서 캠핑을 하는 꿈',
              '숲에서 나무를 심는 꿈',
              '숲속에서 새소리를 듣는 꿈',
              '숲속에서 나무가 쓰러지는 꿈',
              '숲속에서 길을 잃고 헤매는 꿈',
              '숲에서 산책을 하는 꿈',
              '숲에서 모험을 하는 꿈'
            ]
          },
          {
            name: '무지개와 관련된 꿈',
            items: [
              '무지개가 하늘에 뜨는 꿈',
              '무지개가 강에 비추는 꿈',
              '무지개가 바다 위에 뜨는 꿈',
              '무지개가 산 위에 걸리는 꿈',
              '무지개 아래로 걷는 꿈',
              '아름다운 무지개가 뜨는 꿈',
              '무지개가 겹쳐서 보이는 꿈',
              '비온 뒤 무지개가 뜨는 꿈',
              '무지개가 햇빛 속에서 반짝이는 꿈',
              '무지개와 함께 사진을 찍는 꿈'
            ]
          }
        ]
      },
      {
        name: '감정',
        icon: '😊',
        subCategories: [
          {
            name: '행복을 느끼는 꿈',
            items: [
              '가족과 함께 행복을 느끼는 꿈',
              '오랜 친구와 재회하며 행복을 느끼는 꿈',
              '원하는 목표를 이루고 행복을 느끼는 꿈',
              '사랑하는 사람과 함께 행복한 시간을 보내는 꿈',
              '평화롭고 안전한 상황에서 행복을 느끼는 꿈',
              '평화로운 자연 속에서 행복을 느끼는 꿈',
              '승진이나 성취에 대해 행복을 느끼는 꿈',
              '여행 중에 행복을 느끼는 꿈',
              '아기를 보며 행복을 느끼는 꿈',
              '영화나 드라마를 감상하며 행복을 느끼는 꿈'
            ]
          },
          {
            name: '슬픔을 느끼는 꿈',
            items: [
              '가까운 사람을 잃고 슬픔을 느끼는 꿈',
              '사랑하는 사람과 이별하면서 슬픔을 느끼는 꿈',
              '가족이나 일이 실패하여 슬픔을 느끼는 꿈',
              '친구와 갈등으로 인해 슬픔을 느끼는 꿈',
              '중요한 면접을 망쳐서 슬픔을 느끼는 꿈',
              '친구와의 대화 도중 슬픔을 느끼는 꿈',
              '필요한 물건을 잃어버려 슬픔을 느끼는 꿈',
              '과거의 기억을 회상하며 슬픔을 느끼는 꿈',
              '가족에게 상처받아 슬픔을 느끼는 꿈',
              '회사에서 실망하여 눈물을 흘리는 꿈'
            ]
          },
          {
            name: '분노를 느끼는 꿈',
            items: [
              '상사와 다툼으로 인해 분노를 느끼는 꿈',
              '친구에게 배신당하여 분노를 느끼는 꿈',
              '중요한 일이 망가져서 분노를 느끼는 꿈',
              '가족과의 갈등으로 분노를 느끼는 꿈',
              '친구와의 싸움으로 분노를 느끼는 꿈',
              '차량 사고 후 분노를 느끼는 꿈',
              '상사가 부당한 일을 지시하여 분노를 느끼는 꿈',
              '회사에서 불공정한 처우를 받아 분노를 느끼는 꿈',
              '회사의 정책에 불만을 느껴 분노하는 꿈',
              '상사의 질책으로 분노를 느끼는 꿈'
            ]
          },
          {
            name: '두려움을 느끼는 꿈',
            items: [
              '어두운 길을 걷다가 두려움을 느끼는 꿈',
              '높은 곳에서 떨어질 것 같아 두려움을 느끼는 꿈',
              '낯선 사람을 만나서 두려움을 느끼는 꿈',
              '알 수 없는 존재와 마주쳐 두려움을 느끼는 꿈',
              '위험한 동물과 대면하여 두려움을 느끼는 꿈',
              '누군가에게 쫓기며 두려움을 느끼는 꿈',
              '깊은 물에 빠져 두려움을 느끼는 꿈',
              '누군가의 악몽으로 두려움을 느끼는 꿈',
              '자신의 무기력함에 두려움을 느끼는 꿈',
              '자연재해를 겪으며 두려움을 느끼는 꿈'
            ]
          },
          {
            name: '불안을 느끼는 꿈',
            items: [
              '시험 준비가 덜 되어 불안을 느끼는 꿈',
              '정장 입고 발표를 해야 할 때 불안을 느끼는 꿈',
              '중요한 문제를 맞닥뜨리며 불안을 느끼는 꿈',
              '업무에 대한 책임감으로 불안을 느끼는 꿈',
              '미래에 대한 불확실성으로 불안을 느끼는 꿈',
              '비행기 사고를 겪으며 불안을 느끼는 꿈',
              '직장에서 인정을 받지 못해 불안을 느끼는 꿈',
              '상사의 기대에 미치지 못해 불안을 느끼는 꿈',
              '학교 시험에서 실수하여 불안을 느끼는 꿈',
              '자신의 기량에 대한 불안과 걱정을 느끼는 꿈'
            ]
          },
          {
            name: '사랑을 느끼는 꿈',
            items: [
              '연인과 사랑을 나누는 꿈',
              '부모의 사랑을 느끼는 꿈',
              '친구와 깊은 유대감을 느끼는 꿈',
              '집에서 함께 식사를 나누는 꿈',
              '역사적인 상황에서 사랑을 꿈꾸는 꿈',
              '아이에게 사랑을 느끼는 꿈',
              '오래된 연인과의 사랑을 느끼는 꿈',
              '소중한 사람에게 사랑을 고백하는 꿈',
              '사랑하는 사람과 함께 미래를 꿈꾸는 꿈',
              '사랑하는 사람과의 결혼을 준비하는 꿈'
            ]
          },
          {
            name: '실망을 느끼는 꿈',
            items: [
              '중요한 기회를 놓쳐서 실망하는 꿈',
              '친구와 싸우며 이별을 겪는 꿈',
              '가족에게 실망하여 상처받는 꿈',
              '직장에서 인정을 받지 못해 실망하는 꿈',
              '시험 결과에 실망하는 꿈',
              '사랑하는 사람이 떠나가서 실망하는 꿈',
              '이루지 못한 목표에 실망하는 꿈',
              '중요한 발표에서 실망을 겪는 꿈',
              '이직 면접에서 기대에 못 미쳐 실망하는 꿈',
              '자신의 능력에 한계를 느끼고 실망하는 꿈'
            ]
          },
          {
            name: '공허함을 느끼는 꿈',
            items: [
              '성공을 이루었으나 공허함을 느끼는 꿈',
              '가족이나 친구가 없어 공허함을 느끼는 꿈',
              '사랑하는 사람을 잃고 공허함을 느끼는 꿈',
              '목표 달성이 끝난 후 공허함을 느끼는 꿈',
              '일상에서 의미를 잃고 공허함을 느끼는 꿈',
              '업무에서 의미 없는 목표를 공허하게 느끼는 꿈',
              '혼자 남겨져 공허함을 느끼는 꿈',
              '새로운 일을 시작해도 공허함을 느끼는 꿈',
              '과거의 추억을 잃고 공허함을 느끼는 꿈',
              '미래를 위한 계획이 무의미하게 느껴지는 꿈'
            ]
          },
          {
            name: '충격을 느끼는 꿈',
            items: [
              '사고를 당해 충격을 받는 꿈',
              '자신이 말다툼을 하며 충격을 받는 꿈',
              '친구가 사고로 큰 충격을 받는 꿈',
              '일상에서 예상치 못한 충격을 받는 꿈',
              '가족이 예상치 못한 사고를 겪는 꿈',
              '뉴스에서 충격적인 사건을 접하는 꿈',
              '교통사고를 목격하며 충격을 받는 꿈',
              '갑작스러운 질병에 충격을 받는 꿈',
              '중요한 면접에서 실수를 하여 충격을 받는 꿈',
              '회사에서 해고 통보를 받아 충격을 받는 꿈'
            ]
          },
          {
            name: '만족을 느끼는 꿈',
            items: [
              '성과를 이루고 만족감을 느끼는 꿈',
              '가족과 함께 만찬을 즐기며 만족을 느끼는 꿈',
              '친구와의 관계에서 만족감을 느끼는 꿈',
              '직장에서 승진하여 만족감을 느끼는 꿈',
              '목표를 달성하고 큰 만족을 느끼는 꿈',
              '중요한 시험에서 합격하며 만족을 느끼는 꿈',
              '연인이 준비한 깜짝 이벤트에 만족감을 느끼는 꿈',
              '장기적인 프로젝트에서 성공을 이룬 후 만족하는 꿈',
              '직장에서의 인정과 보상에 만족하는 꿈',
              '새로운 업무에서 높은 성과를 거두어 만족하는 꿈'
            ]
          }
        ]
      },
      {
        name: '과거',
        icon: '⏳',
        subCategories: [
          {
            name: '어린 시절로 돌아가는 꿈',
            items: [
              '어린 시절 친구와 다시 만나는 꿈',
              '어린 시절 살았던 집으로 돌아가는 꿈',
              '부모님과 어린 시절을 함께하는 꿈',
              '어린 시절 놀이를 즐기는 꿈',
              '학교 운동장에서 뛰어노는 꿈',
              '어린 시절 적었던 일기를 다시 읽는 꿈',
              '어린 시절 선생님과 다시 만나는 꿈',
              '어릴 적에 느꼈던 감정을 다시 느끼는 꿈',
              '어릴 때 가고 싶었던 장소를 방문하는 꿈',
              '새로운 친구들과 모험을 다시 만나는 꿈'
            ]
          },
          {
            name: '과거의 연인과 관련된 꿈',
            items: [
              '과거의 연인과 다시 만나 대화하는 꿈',
              '과거의 연인과 함께 시간을 보내는 꿈',
              '과거의 연인에게 사과하는 꿈',
              '과거의 연인과 재회하는 꿈',
              '과거의 연인과 행복한 시간을 보내는 꿈',
              '과거의 연인을 잊지 못해 고민하는 꿈',
              '과거의 연인과 함께 여행하는 꿈',
              '과거의 연인과 연애를 다시 시작하는 꿈',
              '과거의 연인을 잊지 못해 회상하는 꿈',
              '과거의 연인에게 편지를 받는 꿈'
            ]
          },
          {
            name: '과거의 친구와 관련된 꿈',
            items: [
              '과거의 친구와 함께 대화하는 꿈',
              '과거의 친구와 다시 모이는 꿈',
              '과거의 친구와 함께 여행을 떠나는 꿈',
              '과거의 친구와 추억을 되새기며 웃는 꿈',
              '과거의 친구와 학창 시절을 회상하는 꿈',
              '과거의 친구와 함께 공부하는 꿈',
              '과거의 친구와 다시 시작하는 꿈',
              '과거의 친구와 같은 직장에서 다시 일하는 꿈',
              '과거의 친구와 비밀을 나누는 꿈',
              '과거의 친구와 우정을 재확인하는 꿈'
            ]
          },
          {
            name: '과거의 가족과 관련된 꿈',
            items: [
              '어린 시절 가족과 함께 있는 시간을 떠올리는 꿈',
              '과거에 겪었던 가족의 갈등을 해결하는 꿈',
              '가족과 함께 추억의 장소를 방문하는 꿈',
              '과거의 가족과 함께 즐거운 시간을 보내는 꿈',
              '과거의 가족과 함께 식사하는 꿈',
              '과거의 가족이 다시 모이는 꿈',
              '가족과 따뜻한 사랑을 다시 느끼는 꿈',
              '가족의 생일을 축하하며 행복한 시간을 보내는 꿈',
              '어린 시절 가족의 집을 다시 방문하는 꿈',
              '가족과 함께 나누는 특별한 대화를 기억하는 꿈'
            ]
          },
          {
            name: '과거의 집과 관련된 꿈',
            items: [
              '과거의 살던 집으로 돌아가는 꿈',
              '과거의 집을 다시 찾는 꿈',
              '과거의 집에서 가족과 함께 사는 꿈',
              '과거의 집을 새로 짓는 꿈',
              '과거의 집에서 일어났던 사건을 회상하는 꿈',
              '과거의 집을 다시 리모델링하는 꿈',
              '과거의 집에서 새로운 가족을 만나는 꿈',
              '과거의 집을 정리하는 꿈',
              '과거의 집에 방문하는 꿈',
              '과거의 집을 팔거나 새로 구입하는 꿈'
            ]
          },
          {
            name: '과거의 직장과 관련된 꿈',
            items: [
              '과거의 직장 상사와 다시 일하는 꿈',
              '과거의 직장 동료와 재회하는 꿈',
              '과거의 직장에서 프로젝트를 다시 진행하는 꿈',
              '과거의 직장에서 승진하는 꿈',
              '과거의 직장에서 목표를 해결하는 꿈',
              '과거의 직장에서 실패를 극복하는 꿈',
              '과거의 직장에서 떠난 이유를 생각하는 꿈',
              '과거의 직장에서 잊혀진 시간을 되돌리는 꿈',
              '과거의 직장으로 다시 돌아가는 꿈',
              '과거의 직장에서 새로운 기회를 얻는 꿈'
            ]
          },
          {
            name: '과거의 학교와 관련된 꿈',
            items: [
              '과거 학교에서 수업을 듣는 꿈',
              '과거의 학교 친구들과 다시 만나는 꿈',
              '과거의 시험을 다시 치르는 꿈',
              '과거의 선생님을 다시 만나는 꿈',
              '학교에서 인정을 받지 못해 실망하는 꿈',
              '과거의 학교에서 성공적인 결과를 얻는 꿈',
              '과거의 학교에서 학창 시절을 회상하는 꿈',
              '과거의 학교 운동장에서 뛰노는 꿈',
              '과거의 학교에서 졸업하는 꿈',
              '과거의 학교에서 동창을 만나는 꿈'
            ]
          },
          {
            name: '과거의 사건과 관련된 꿈',
            items: [
              '과거의 실수를 다시 떠올리는 꿈',
              '과거의 경험을 바탕으로 문제를 해결하는 꿈',
              '과거의 프로젝트를 기억해내는 꿈',
              '친구와의 싸움을 되돌아보는 꿈',
              '과거의 성과를 재평가하는 꿈',
              '과거의 사건을 통해 현재를 바꾸는 꿈',
              '과거의 실수를 바로잡고 성공하는 꿈',
              '과거의 결정을 후회하는 꿈',
              '과거의 상처를 다시 치유하는 꿈',
              '과거의 성공을 다시 기억하는 꿈'
            ]
          },
          {
            name: '과거의 실수와 후회와 관련된 꿈',
            items: [
              '과거의 실수를 다시 떠올리는 꿈',
              '과거의 문제점을 바로잡는 꿈',
              '과거의 갈등을 해결하는 꿈',
              '과거의 상처를 다시 치료하는 꿈',
              '과거의 실수로 인해 얻은 교훈을 배우는 꿈',
              '과거의 실수를 통해 새로운 출발을 하는 꿈',
              '과거의 후회를 되풀이하지 않으려 노력하는 꿈',
              '과거의 경험에서 벗어나 앞으로 나아가는 꿈',
              '과거의 아쉬움을 극복하는 꿈',
              '과거의 실수로부터 새로운 교훈을 얻는 꿈'
            ]
          },
          {
            name: '과거의 추억과 관련된 꿈',
            items: [
              '어린 시절의 추억을 떠올리는 꿈',
              '과거의 여행을 회상하는 꿈',
              '과거의 친구와 함께 했던 추억을 떠올리는 꿈',
              '가족과의 행복한 추억을 다시 경험하는 꿈',
              '과거의 집에서 일어났던 추억을 되새기는 꿈',
              '과거의 추억이 현실과 겹치는 꿈',
              '과거의 직장에서 동료와의 추억을 떠올리는 꿈',
              '과거의 중요한 인물과의 추억을 기억하는 꿈',
              '과거의 기념일을 다시 회상하는 꿈',
              '과거의 추억이 현실로 돌아오는 꿈'
            ]
          }
        ]
      },
      {
        name: '죽음',
        icon: '💀',
        subCategories: [
          {
            name: '자신의 죽음과 관련된 꿈',
            items: [
              '자신이 물에 빠져 죽는 꿈',
              '불에 타 죽는 꿈',
              '교통사고로 죽는 꿈',
              '높은 곳에서 떨어져 죽는 꿈',
              '칼에 맞아 죽는 꿈',
              '병에 걸려 죽는 꿈',
              '자신이 독약을 먹고 죽는 꿈',
              '전쟁에서 죽는 꿈',
              '자신의 죽음 영혼이 떠나는 꿈'
            ]
          },
          {
            name: '가족의 죽음과 관련된 꿈',
            items: [
              '부모님이 병으로 죽는 꿈',
              '자녀가 사고로 죽는 꿈',
              '형제/자매가 교통사고로 죽는 꿈',
              '할머니/할아버지가 병으로 죽는 꿈',
              '부모님이 살해당하는 꿈',
              '자녀가 혈액암으로 죽는 꿈',
              '가족이 전쟁에서 죽는 꿈',
              '배우자가 납치된 후 죽는 꿈',
              '자녀가 나이 들어 죽는 꿈',
              '가족이 익사하는 꿈'
            ]
          },
          {
            name: '친구의 죽음과 관련된 꿈',
            items: [
              '친구가 교통사고로 죽는 꿈',
              '친구가 질병으로 죽는 꿈',
              '친구가 납치된 후 죽는 꿈',
              '친구가 물에 빠져 죽는 꿈',
              '친구가 자살하는 꿈',
              '친구가 총에 맞아 죽는 꿈',
              '친구가 병원에서 죽는 꿈',
              '친구의 장례식을 준비하는 꿈',
              '친구와 함께 죽는 꿈',
              '친구의 죽음을 추모하는 꿈'
            ]
          },
          {
            name: '직장 동료의 죽음과 관련된 꿈',
            items: [
              '직장 동료가 병으로 죽는 꿈',
              '동료가 사고로 죽는 꿈',
              '동료가 불에 타 죽는 꿈',
              '동료가 총에 맞아 죽는 꿈',
              '동료가 질병으로 죽는 꿈',
              '동료가 살해당하는 꿈',
              '동료가 익사하는 꿈',
              '동료와 함께 죽는 꿈',
              '동료가 남서 사고로 죽는 꿈',
              '동료의 장례식을 준비하는 꿈'
            ]
          },
          {
            name: '동물의 죽음과 관련된 꿈',
            items: [
              '애완견이 죽는 꿈',
              '애완고양이가 죽는 꿈',
              '새가 사고로 죽는 꿈',
              '고양이가 물에 빠져 죽는 꿈',
              '강아지가 차에 치여 죽는 꿈',
              '애완동물을 잃어버리는 꿈',
              '동물이 죽는 장면을 목격하는 꿈',
              '동물을 모두 잃는 꿈',
              '동물들이 함께 죽는 꿈',
              '작은 동물을 밟아 죽이는 꿈'
            ]
          },
          {
            name: '죽은 사람과 상호작용과 관련된 꿈',
            items: [
              '죽은 가족과 대화하는 꿈',
              '죽은 친구와 연락하는 꿈',
              '죽은 배우자와 다시 만나는 꿈',
              '죽은 사람이 자신에게 무엇인가 주는 꿈',
              '죽은 사람이 자신을 도와주는 꿈',
              '죽은 사람과 함께 식사하는 꿈',
              '죽은 사람과 대화를 하는 꿈',
              '죽은 사람에게 도움을 청하는 꿈',
              '죽은 사람과 함께 걷는 꿈',
              '죽은 사람을 환영하는 꿈'
            ]
          },
          {
            name: '죽음의 상징물과 관련된 꿈',
            items: [
              '무덤을 보고 있는 꿈',
              '자신이 죽음 속에 누워 있는 꿈',
              '묘지에서 죽은 이를 찾는 꿈',
              '관에서 일어나는 꿈',
              '유령을 보는 꿈',
              '죽음의 그림자를 보는 꿈',
              '칼을 휘두르는 꿈',
              '관에 몸을 넣는 꿈',
              '해골을 보는 꿈',
              '무덤을 열어보는 꿈'
            ]
          },
          {
            name: '죽음의 메시지를 받는 꿈',
            items: [
              '죽은 사람이 찾아와 경고하는 꿈',
              '죽은 사람이 나에게 메시지를 전하는 꿈',
              '죽은 사람이 나를 데려가겠다는 꿈',
              '죽음의 소식을 전하는 꿈',
              '죽음의 경고를 듣는 꿈',
              '죽음의 신이 나타나는 꿈',
              '피가 보이는 꿈',
              '사체가 있는 꿈',
              '죽은 사람이 눈을 뜨는 꿈',
              '무덤 앞에서 누군가를 만나는 꿈'
            ]
          },
          {
            name: '죽음 이후의 세계와 관련된 꿈',
            items: [
              '자신이 죽음의 세계로 가는 꿈',
              '자신의 혼이 나서 저승으로 가는 꿈',
              '죽음 이후의 삶을 사는 꿈',
              '죽은 이후 하늘을 나는 꿈',
              '죽은 이후 세상을 보는 꿈',
              '죽은 이후 새로운 인생을 시작하는 꿈',
              '죽은 이후 천사를 만나는 꿈',
              '죽은 이후 영혼들이 싸우는 꿈',
              '죽은 이후 신과 대화하는 꿈',
              '죽은 후 영혼이 떠도는 꿈'
            ]
          },
          {
            name: '죽음의 징조와 관련된 꿈',
            items: [
              '검은 사자가 나타나는 꿈',
              '피가 보이는 꿈',
              '사체가 발견되는 꿈',
              '죽은 사람이 방문하는 꿈',
              '자신의 유언을 듣는 꿈',
              '죽음과 관련된 소식이 들리는 꿈',
              '죽음을 암시하는 숫자가 보이는 꿈',
              '폭력적인 죽음의 장면이 보이는 꿈',
              '죽은 사람이 나를 부르는 꿈',
              '무덤 앞에서 누군가를 만나는 꿈'
            ]
          }
        ]
      },
      {
        name: '인간관계',
        icon: '👥',
        subCategories: [
          {
            name: '가족과 관련된 꿈',
            items: [
              '가족과 함께 식사하는 꿈',
              '가족 간의 갈등을 해결하는 꿈',
              '오랜만에 가족과 재회하는 꿈',
              '가족 여행을 떠나는 꿈',
              '가족이 아프거나 건강이 악화되는 꿈',
              '가족이 즐겁게 시간을 보내는 꿈',
              '가족들과의 기념일을 축하하는 꿈',
              '가족을 잃고 슬픔을 느끼는 꿈',
              '가족이 이사하는 꿈',
              '가족과 함께 시간을 보내는 꿈'
            ]
          },
          {
            name: '직장 동료와 관련된 꿈',
            items: [
              '동료와 함께 일하며 프로젝트를 성공시키는 꿈',
              '동료와의 의견 충돌이 생기는 꿈',
              '동료의 신뢰를 잃는 꿈',
              '직장 동료와 회식을 하는 꿈',
              '동료가 승진하는 것을 보는 꿈',
              '동료가 직장을 떠나는 꿈',
              '동료에게 칭찬을 받는 꿈',
              '동료와의 불화를 느끼는 꿈',
              '동료와의 우정을 재확인하는 꿈',
              '동료가 새로운 직무로 이동하는 꿈'
            ]
          },
          {
            name: '친구와 관련된 꿈',
            items: [
              '친구와 함께 즐거운 시간을 보내는 꿈',
              '친구에게 중요한 비밀을 공유하는 꿈',
              '친구와 다투는 꿈',
              '친구의 부탁을 들어주는 꿈',
              '친구가 나에게 실망하는 꿈',
              '친구가 결혼하는 꿈',
              '오랜만에 친구와 다시 만나는 꿈',
              '친구에게 좋은 소식을 듣는 꿈',
              '친구와 함께 여행을 가는 꿈',
              '친구와 갈등을 해소하는 꿈'
            ]
          },
          {
            name: '동창과 관련된 꿈',
            items: [
              '동창회에서 오랜 친구들을 만나는 꿈',
              '동창과 함께 학창 시절을 회상하는 꿈',
              '동창과 함께 여행을 떠나는 꿈',
              '동창과 오랜만에 만나는 꿈',
              '동창과 함께 운동하는 꿈',
              '동창들과 모여서 웃고 떠드는 꿈',
              '동창들과 추억을 나누는 꿈',
              '동창이 성공한 모습을 보는 꿈',
              '동창과의 경쟁에서 승리하는 꿈',
              '동창에게 조언을 받는 꿈'
            ]
          },
          {
            name: '연인과 관련된 꿈',
            items: [
              '연인과 함께 낭만적인 시간을 보내는 꿈',
              '연인과 함께 여행하는 꿈',
              '연인과 다투는 꿈',
              '연인에게 고백을 받는 꿈',
              '연인과 함께 기념일을 축하하는 꿈',
              '연인과 이별하는 꿈',
              '연인과 함께 새로운 시작을 하는 꿈',
              '연인에게 선물을 받는 꿈',
              '연인이 나를 실망시키는 꿈',
              '연인과 결혼을 계획하는 꿈'
            ]
          },
          {
            name: '옛 연인과 관련된 꿈',
            items: [
              '옛 연인과 우연히 재회하는 꿈',
              '옛 연인과 다시 시작하는 꿈',
              '옛 연인과 대화하는 꿈',
              '옛 연인에게 사과하는 꿈',
              '옛 연인과의 추억을 되새기는 꿈',
              '옛 연인과 새로운 사람과 함께 있는 꿈',
              '옛 연인이 결혼하는 꿈',
              '옛 연인에게 선물을 받는 꿈',
              '옛 연인과 함께 식사를 하는 꿈',
              '옛 연인에게 마침내 이별을 고하는 꿈'
            ]
          },
          {
            name: '이웃과 관련된 꿈',
            items: [
              '이웃과 함께 시간을 보내는 꿈',
              '이웃과 함께 음식을 나누는 꿈',
              '이웃이 나에게 도움을 주는 꿈',
              '이웃과 갈등을 겪는 꿈',
              '이웃이 나에게 선물을 주는 꿈',
              '이웃과 함께 이사하는 꿈',
              '이웃이 갑자기 이사 가는 꿈',
              '이웃과 길에서 마주치는 꿈',
              '이웃과 친밀한 관계를 맺는 꿈',
              '이웃이 어려운 상황에 처하는 꿈'
            ]
          },
          {
            name: '친척과 관련된 꿈',
            items: [
              '오랜만에 친척과 재회하는 꿈',
              '친척과 함께 식사하는 꿈',
              '친척이 나에게 조언을 해주는 꿈',
              '친척과 함께 여행을 가는 꿈',
              '친척이 건강이 나빠지는 꿈',
              '친척과의 갈등을 해결하는 꿈',
              '친척과의 결혼식에 참석하는 꿈',
              '친척에게 선물을 받는 꿈',
              '친척과의 사업을 시작하는 꿈',
              '친척이 병원에 입원하는 꿈'
            ]
          },
          {
            name: '우연한 인연과 관련된 꿈',
            items: [
              '우연히 만난 친구와 대화하는 꿈',
              '우연히 새로운 사람을 만나는 꿈',
              '우연히 사람들과 음식을 나누는 꿈',
              '우연히 만난 인연과 함께 시간을 보내는 꿈',
              '우연히 길을 걷다가 인연을 만나는 꿈',
              '우연히 누군가와 연락이 닿는 꿈',
              '우연히 만난 사람이 친구가 되는 꿈',
              '우연히 친구를 만나는 꿈',
              '우연히 결혼식에서 새로운 사람을 만나는 꿈',
              '우연히 만난 인연과 중요한 관계를 맺는 꿈'
            ]
          }
        ]
      },
      {
        name: '유명인물',
        icon: '🎬',
        subCategories: [
           {
            name: '유명인사와 대화하는 꿈',
            items: [
              '유명인사와 비즈니스 관련 이야기를 나누는 꿈',
              '유명인사에게 칭찬을 받는 꿈',
              '유명인사에게 조언을 구하는 꿈',
              '유명인사와 논쟁하는 꿈',
              '유명인사가 나에게 비밀을 털어놓는 꿈',
              '유명인사와 중요한 주제를 논의하는 꿈',
              '유명인사의 가족과 함께 이야기를 나누는 꿈',
              '유명인사에게 나의 문제를 이야기하는 꿈',
              '유명인사의 미래에 대해 이야기를 나누는 꿈',
              '유명인사가 나의 인생을 격려해주는 꿈'
            ]
          },
          {
            name: '유명인사와 사진을 찍는 꿈',
            items: [
              '유명인사와 기념사진을 찍는 꿈',
              '유명인사와 함께 셀카를 찍는 꿈',
              '유명인사와 여러 사람들과 단체 사진을 찍는 꿈',
              '유명인사가 나와 사진을 찍어주는 꿈',
              '유명인사가 내 사진을 찍어주는 꿈',
              '유명인사와 공공장소에서 사진을 찍는 꿈',
              '유명인사와 포즈를 취하며 사진을 찍는 꿈',
              '유명인사와 특별한 장소에서 사진을 찍는 꿈',
              '유명인사와 인터뷰하는 중에 사진을 찍는 꿈',
              '유명인사와 사진을 찍고 우정을 쌓는 꿈'
            ]
          },
          {
            name: '유명인사와 함께 일하는 꿈',
            items: [
              '유명인사와 프로젝트를 진행하는 꿈',
              '유명인사와 비즈니스를 함께 하는 꿈',
              '유명인사와 회의하는 꿈',
              '유명인사와 리더십을 나누는 꿈',
              '유명인사가 내 직무를 돕는 꿈',
              '유명인사와 협력하여 성공을 이루는 꿈',
              '유명인사와 연예계를 함께하는 꿈',
              '유명인사와 영화 촬영을 하는 꿈',
              '유명인사와 새로운 사업을 시작하는 꿈',
              '유명인사와 성과를 달성하는 꿈'
            ]
          },
          {
            name: '유명인사에게 도움을 받는 꿈',
            items: [
              '유명인사가 나를 돕는 꿈',
              '유명인사가 나의 문제를 해결하는 꿈',
              '유명인사에게 금전적인 도움을 받는 꿈',
              '유명인사가 나에게 좋은 기회를 제공해주는 꿈',
              '유명인사가 나의 직무를 돕는 꿈',
              '유명인사에게서 중요한 지식을 배우는 꿈',
              '유명인사에게 인생 조언을 받는 꿈',
              '유명인사가 나의 위기 상황을 해결해주는 꿈',
              '유명인사와 함께 일을 해결하는 꿈',
              '유명인사에게서 비밀을 듣는 꿈'
            ]
          },
          {
            name: '유명인사와 함께 여행하는 꿈',
            items: [
              '유명인사와 해외로 여행을 떠나는 꿈',
              '유명인사와 비행기를 함께 타는 꿈',
              '유명인사와 유명 관광지를 방문하는 꿈',
              '유명인사와 여행 중에 모험을 가는 꿈',
              '유명인사와 고급 호텔에서 머무는 꿈',
              '유명인사와 산을 오르는 꿈',
              '유명인사와 바닷가에서 수영하는 꿈',
              '유명인사와 함께 음식을 먹는 꿈',
              '유명인사와 크루즈 여행을 하는 꿈',
              '유명인사와 여행 중에 만난 모험을 함께하는 꿈'
            ]
          },
          {
            name: '유명인사와 식사하는 꿈',
            items: [
              '유명인사와 고급 레스토랑에서 식사하는 꿈',
              '유명인사와 함께 디너를 즐기는 꿈',
              '유명인사에게 요리를 대접받는 꿈',
              '유명인사와 함께 요리를 하는 꿈',
              '유명인사와 함께 음식을 맛보는 꿈',
              '유명인사와 길거리 음식을 함께 먹는 꿈',
              '유명인사와 함께 테이블을 세팅하는 꿈',
              '유명인사와 함께 미식을 경험하는 꿈',
              '유명인사와 함께 샴페인을 마시는 꿈',
              '유명인사와 만찬을 즐기며 웃음을 나누는 꿈'
            ]
          },
          {
            name: '유명인사를 우연히 만나는 꿈',
            items: [
              '길을 걷다가 유명인사를 만나는 꿈',
              '카페에서 유명인사를 우연히 마주치는 꿈',
              '공항에서 유명인사를 마주치는 꿈',
              '유명인사가 나의 꿈속에서 나타나는 꿈',
              '유명인사와 우연히 만나서 대화를 나누는 꿈',
              '레스토랑에서 유명인사를 우연히 보는 꿈',
              '유명인사가 나에게 다가오는 꿈',
              '유명인사와 함께 길을 걷는 꿈',
              '유명인사와 이야기를 나누는 꿈',
              '유명인사와 우연한 상황에서 만나 대화를 나누는 꿈'
            ]
          },
          {
            name: '유명인사와 공연을 보는 꿈',
            items: [
              '유명인사와 함께 콘서트를 보는 꿈',
              '유명인사와 뮤지컬을 관람하는 꿈',
              '유명인사와 함께 영화관에 앉아있는 꿈',
              '유명인사와 함께 연극을 관람하는 꿈',
              '유명인사와 공연을 함께 보며 즐거워하는 꿈',
              '유명인사와 VIP 좌석에서 공연을 관람하는 꿈',
              '유명인사와 함께 오페라를 관람하는 꿈',
              '유명인사와 함께 야외 콘서트를 보는 꿈',
              '유명인사와 함께 팬미팅에 참여하는 꿈',
              '유명인사와 무대에서 가까운 곳에서 공연을 보는 꿈'
            ]
          },
          {
            name: '유명인사와 인터뷰하는 꿈',
            items: [
              '유명인사와 인터뷰를 진행하는 꿈',
              '유명인사와 잡지 인터뷰를 하는 꿈',
              '유명인사와 TV 방송에 출연하는 꿈',
              '유명인사와 함께 기자회견을 하는 꿈',
              '유명인사와 함께 연설하는 꿈',
              '유명인사와의 인터뷰를 기록하는 꿈',
              '유명인사와 함께 라디오에 출연하는 꿈',
              '유명인사와 기자와 함께 인터뷰하는 꿈',
              '유명인사와의 인터뷰에서 질문을 받는 꿈',
              '유명인사와 대중 앞에서 인터뷰를 하는 꿈'
            ]
          },
          {
            name: '유명인사와 행사를 함께하는 꿈',
            items: [
              '유명인사의 파티에 초대받는 꿈',
              '유명인사와 시상식에 함께 참석하는 꿈',
              '유명인사와 VIP 행사에 참여하는 꿈',
              '유명인사와 함께 레드카펫을 걷는 꿈',
              '유명인사와 함께 영화 시사회에 참석하는 꿈',
              '유명인사와 함께 기부 행사를 여는 꿈',
              '유명인사와 자선 활동을 하는 꿈',
              '유명인사와 패션쇼에 함께 참석하는 꿈',
              '유명인사와 회식에 참여하는 꿈',
              '유명인사와 함께 경매에 참여하는 꿈'
            ]
          }
        ]
      },
    ]
}