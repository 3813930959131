var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:['header', _vm.headerClass, { scrolled: _vm.isScrolled }]},[(_vm.$route.path === '/' || _vm.$route.path === '/home')?[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/ic-system-logo.svg")},on:{"click":function($event){return _vm.$router.replace('/')}}}),_c('img',{staticClass:"qr",attrs:{"src":require("@/assets/ic-system-qr.svg")},on:{"click":_vm.onQr}}),_c('button',{staticClass:"share",on:{"click":_vm.onShare}},[_vm._v("공유")])]:(
      _vm.$route.path === '/ai' ||
      _vm.$route.path === '/dream' ||
      _vm.$route.path === '/category' ||
      _vm.$route.path === '/category2' ||
      _vm.$route.path === '/category3' ||
      _vm.$route.path === '/random' ||
      _vm.$route.path === '/result' ||
      _vm.$route.path === '/manual' ||
      _vm.$route.path === '/fortuneInfo' ||
      _vm.$route.path === '/today' ||
      _vm.$route.path === '/loading' ||
      _vm.$route.path === '/fortune-loading' ||
      _vm.$route.path === '/after-login'
    )?[_c('button',{staticClass:"back",on:{"click":_vm.goBack}},[_c('img',{attrs:{"src":require("@/assets/ic-system-back-img.svg")}})]),_c('div',{staticClass:"menu"},[_vm._v(_vm._s(_vm.menuName))])]:(_vm.$route.path === '/select-hope')?[_c('div',{staticClass:"empty"}),_c('div',{staticClass:"menu"},[_vm._v("소망 선택")]),_c('button',{staticClass:"close",on:{"click":_vm.goBackHope}},[_c('img',{attrs:{"src":require("@/assets/ic-system-close-img.svg")}})])]:(_vm.$route.path === '/chatGPT')?[_c('div',{staticClass:"empty"}),_c('div',{staticClass:"empty"}),_c('button',{staticClass:"close",on:{"click":_vm.goClose}},[_c('img',{attrs:{"src":require("@/assets/ic-system-close-img.svg")}})])]:(_vm.$route.path === '/qr' || _vm.$route.path === '/login')?[_c('div',{staticClass:"empty"}),_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/ic-system-logo.svg")},on:{"click":function($event){return _vm.$router.replace('/')}}}),_c('button',{staticClass:"close",on:{"click":_vm.goClose}},[_c('img',{attrs:{"src":require("@/assets/ic-system-close-img.svg")}})])]:(_vm.$route.path === '/my')?[_c('div',{staticClass:"empty"}),_c('div',{staticClass:"menu"},[_vm._v("더보기")]),(_vm.isLoggedIn || !_vm.isNotLoggedIn)?_c('img',{attrs:{"src":require("@/assets/ic-setting.svg")},on:{"click":_vm.onSetting}}):_vm._e()]:(
      _vm.$route.path === '/my/number' &&
      (_vm.$route.query.tab === 'automatic' || _vm.$route.query.tab === 'manual')
    )?[_c('button',{staticClass:"back",on:{"click":_vm.goClose}},[_c('img',{attrs:{"src":require("@/assets/ic-system-back-img.svg")}})]),_c('div',{staticClass:"menu"},[_vm._v("나의 로또번호")]),_c('button',{staticClass:"share",on:{"click":_vm.onSelectedRound}},[_vm._v("회차")])]:(_vm.$route.path === '/my/number')?[_c('button',{staticClass:"back",on:{"click":_vm.goBack}},[_c('img',{attrs:{"src":require("@/assets/ic-system-back-img.svg")}})]),_c('div',{staticClass:"menu"},[_vm._v("나의 로또번호")]),_c('button',{staticClass:"share",on:{"click":_vm.onSelectedRound}},[_vm._v("회차")])]:(_vm.$route.path === '/my/setting')?[_c('button',{staticClass:"back",on:{"click":_vm.goBack}},[_c('img',{attrs:{"src":require("@/assets/ic-system-back-img.svg")}})]),_c('div',{staticClass:"menu"},[_vm._v("설정")]),_c('div',{staticClass:"empty"})]:(_vm.$route.path === '/leave')?[_c('button',{staticClass:"back",on:{"click":_vm.goBack}},[_c('img',{attrs:{"src":require("@/assets/ic-system-back-img.svg")}})]),_c('div',{staticClass:"menu"},[_vm._v("탈퇴하기")]),_c('div',{staticClass:"empty"})]:(_vm.$route.path === '/guess' || _vm.$route.path === '/Guess')?[_c('div',{staticClass:"empty"}),_c('div',{staticClass:"menu"},[_vm._v(_vm._s(_vm.menuName))]),_c('button',{staticClass:"use",on:{"click":_vm.use}},[_vm._v("안내")])]:(_vm.$route.path === '/challenge')?[_c('button',{staticClass:"back",on:{"click":_vm.goBackChallenge}},[_c('img',{attrs:{"src":require("@/assets/ic-system-back-img.svg")}})]),_c('div',{staticClass:"menu"},[_vm._v("로또 맞추기")]),_c('div',{staticClass:"empty"})]:(_vm.$route.path === '/terms')?[_c('div',{staticClass:"empty"}),_c('div',{staticClass:"menu"},[_vm._v("개인 정보 보호 및 약관")]),_c('button',{staticClass:"close",on:{"click":_vm.goBack}},[_c('img',{attrs:{"src":require("@/assets/ic-system-close-img.svg")}})])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }